import axios from 'axios'
import { Message } from 'element-ui';
import router from '../router';

const http = axios.create({
    baseURL: 'https://appi.tokenporket.pro/api/',
    // baseURL: 'http://192.168.9.102/api/',
    timeout: 15000
});


http.interceptors.request.use(function (config) {
    var token = window.localStorage.getItem('token');
    if (token) {
        var configData = config['data'] ? config['data'] : "";
        var data = {};
        if (config['data']) {
            for (const key in configData) {
                data[key] = configData[key];
            }
        }
        data['token'] = token;
        config.data = data;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

http.interceptors.response.use(function (response) {
    if (response.status == 200) {
        if (response.data.code == -10000) {
            Message.error('登录已过期，请重新登录');
            window.localStorage.removeItem('token');
            router.replace('/login');
            return;
        }
    }
    return response.data;
}, function (error) {
    Message.error('登录已过期，请重新登录');
    window.localStorage.removeItem('token');
    router.replace('/login');
    return Promise.reject(error);
});

export default http;