import Vue from 'vue'
import VueRouter from 'vue-router'
import http from '../utils/http'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import("@/view/main/dashboards/analytics"),
    meta: {
      isLogin: true,
      title: "超级后台管理中心"
    }
  },
  {
    path: "/wallet",
    component: () => import("@/view/pages/wallet"),
    meta: {
      title: "钱包",
      isLogin: false,
      layout: "",
    },
  },
  {
    path: "/login",
    component: () => import("@/view/pages/authentication/login"),
    meta: {
      title: "登陆会员",
      isLogin: false,
      layout: "full",
    },
  },
  {
    path: "/pages/pricing",
    name: "pricing",
    component: () => import("@/view/pages/pricing"),
    meta: {
      title: "Pricing",
      breadcrumb: [
        {
          text: "Pages"
        }
      ]
    },
  },
  {
    path: "/pages/profile",
    name: "profile-personel-information",
    component: () => import("@/view/pages/profile"),
    meta: {
      title: "个人中心",
      isLogin: true,
      breadcrumb: [
        {
          text: "Pages"
        }
      ]
    },
  },
  {
    path: "/pages/profile/password-change",
    name: "profile-password-change",
    component: () => import("@/view/pages/profile"),
    meta: {
      title: "Profile",
      breadcrumb: [
        {
          text: "Pages"
        }
      ]
    },
  },
  {
    path: '*',
    component: () => import("@/view/pages/errors/404"),
    meta: {
      title: "404-找不到页面哦",
      layout: "full",
      isLogin: false,
    },
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes
})

router.afterEach((to, form) => {
  window.document.title = to.meta.title;
})

var GetRouterCode = false;

router.beforeEach(async (to, from, next) => {
  var token = window.localStorage.getItem('token');
  if (to.meta.isLogin) {
    if (!token) {
      next('/login');
    }
  }

  //路由拉取
  if(GetRouterCode){
    next();
  }else{
    if (to.path == '/login') {
      next();
    }
    getuser((UserRouter)=>{
      router.addRoutes(UserRouter);
      GetRouterCode = true;
      next(to.path);
    }); 
  }
})


async function getuser(ck) {
  var  UserRouter = [];
  var ret = await http.post('/user_api/routerList');
    if (ret) {
      if (ret.code == 1) {
        for (let index = 0; index < ret.list.length; index++) {
          var rou = {
            path: ret.list[index]['path'],
            component: () => import("@/view/pages/" + ret.list[index]['component']),
            meta: {
              title: ret.list[index]['meta']['title'],
              isLogin: ret.list[index]['meta']['isLogin'],
              layout: ret.list[index]['meta']['layout'],
            },
          };
          UserRouter.push(rou);
        }
        ck(UserRouter);
      }
    }
}

export default router