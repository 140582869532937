import http from '../../utils/http'


export default {
    state:{
        id: null,
        token: "",
        email: "",
        nickname: "",
        ip_address: "",
        daili: false
    },
    mutations:{
        async getuser(state){
            var obj = {
                id: null,
                token: "",
                email: "",
                nickname: "",
                ip_address: "",
                daili: false
            };
        
            var token = window.localStorage.getItem('token');
            if (!token) {
                return obj;
            }
        
            var ret = await http.post('/user_api/get_user');
            if (ret) {
                if (ret.code == 1) {
                    for (const key in ret.user) {
                        for (const key2 in state) {
                            if (key == key2) {
                                state[key2] = ret.user[key];
                            }
                        }
                    }
                }
            }
        }
    }
};